<template>
  <domain-items-list
    modal-id="services-modal"
    modal-title="Service"
    :api="{
      getItemsRequest, addItemRequest, updateItemRequest, resetItem, toggleStatus,
    }"
    :item.sync="item"
  >
    <template #additional-inputs="{item}">
      <!-- Description -->
      <text-area-input
        id="description"
        name="Description"
        rules="max:500"
        :text.sync="item.description"
      />
      <category-type-select
        :category.sync="item.category"
      />
    </template>
    <template #additional-filters="{searchQuery}">
      <b-col
        md="6"
      >
        <category-type-select
          :category.sync="searchQuery.category"
        />
      </b-col>
    </template>
  </domain-items-list>
</template>
<script>
import DomainItemsList from '@/common/components/GeneralSettings/domain-items/DomainItemsList.vue'
import domainItemApi from '@/common/compositions/DomainItems/domainItemApi'
import CategoryTypeSelect from '@/common/components/GeneralSettings/common/CategoryTypeSelect.vue'
import TextAreaInput from '@/common/components/common/FormInputs/TextAreaInput.vue'

export default {
  name: 'Services',
  components: {
    DomainItemsList,
    CategoryTypeSelect,
    TextAreaInput,
  },
  provide() {
    return {
      resource: 'global-ServiceController',
    }
  },
  setup() {
    const {
      item, getItemsRequest, addItemRequest, updateItemRequest, resetItem, toggleStatus,
    } = domainItemApi('entities', '/internalops/service')

    return {
      item, getItemsRequest, addItemRequest, updateItemRequest, resetItem, toggleStatus,
    }
  },
}
</script>
<style lang="">

</style>
